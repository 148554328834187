@import 'assets/stylesheet/variables.scss';


.ant-calendar-picker{
    width:100%;
}
.create-description-container form .ant-form-item .ant-col .ant-form-item-control span textarea{
    padding:2% 12px !important;
}

.create-assignment-container .description-card .description-container .assignee .assignee-item {
    width:100% !important;
}
.create-assignment-container {
    // display: flex;
    height: 100%;
    overflow: auto;
    border-radius: $theme-border-radius;
    // min-width: 50vw;
    width: 100%;

    .flex {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    @media (max-width:480px) {
        .flex{width: 90%;}
    }



    .bookmark-icon {
        box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.11);
    }

    .left {
        flex: 0.4;
        position: relative;

        .pack-points {
            margin-bottom: 15px;
            font-size: 12px;

            .read-time {
                border-radius: 14px;
                background-color: #ffffff;
                color: $theme-color;
                font-size: 12px;
                padding: 2px 6px;
                display: flex;
                height: 20px;
                width: fit-content;
            }
        }

        .left-top {
            position: absolute;
            top: 0px;
            padding-left: 23px;
            padding-top: 24px;
            color: #fff;
            // For shadow
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0.76) 0%, rgba(0, 0, 0, 0) 100%);
            width: 100%;
            padding: 19px;

            .left-heading {
                margin-top: 25px;
                color: #fff;
                font-size: 26px;
                font-weight: 500;
            }
        }

        .story-card {
            position: absolute;
            bottom: 0px;
            padding-left: 23px;
            padding-bottom: 24px;
            color: #fff;
            // For shadow
            background: linear-gradient(to top, rgba(0, 0, 0, 0.76) 0%, rgba(0, 0, 0, 0) 100%);
            width: 100%;
            padding: 19px;
            padding-top: 40px;

            .name {
                font-size: 26px;
                font-weight: 500;
            }

            .author-name {
                font-size: 16px;
            }
        }

        .bg-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            background-image: linear-gradient(359deg, #552d83, #432870 64%, #361e5f);
            border-radius: $theme-border-radius;
        }

        .left-header {
            padding-left: 30px;
            padding-top: 15px;
            text-shadow: 0 4px 10px rgba(0, 0, 0, 0.7);
            font-family: Rubik;
            font-size: 26px;
            font-weight: 500;
            line-height: 1.23;
            color: #fff;
            position: absolute;
        }

        .mobile-back {
            display: none;
        }

        .mobile-bookmark {
            display: none;
        }
    }

    .create-description-card {
        border-radius: $theme-border-radius;
        background-color: #f1f1f5;
        padding: 16px;

        .close-icon {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: $magenta-color;
            padding: 10px;
            cursor: pointer;
        }

        .success-container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-top: 50px;
        }

        .success-container-2 {
            .success-inner {
                display: flex;
            }

            button {
                background-color: $magenta-color;
                margin-top: 10px;
            }
        }

        .description-container {
            height: 80%;
            position: relative;
            // overflow: auto;
            align-items: center;
            margin: 10px 0;
            padding-right: 5px;

            .assignee {
                display: flex;
                justify-content: space-between;

                .assignee-item {
                    width: 30%;
                }
            }


        }

        .btn-container {
            position: absolute;
            right: 0;
            bottom: 0px;
            // button {
            //   width: 218px;
            //   height: 52px;
            //   padding: 0px;
            // }
        }

        .total-activities-text {
            font-weight: bold;
            color: $theme-text-color-dark;
            font-size: 20px;
            text-align: center;
        }

        .summary-card {
            max-width: 440px;

            .summary-collapse {
                background-color: #f1f1f5;
                padding: 5px;

                .summary-collapse-panel {
                    background: #ffffff;
                    border-radius: $theme-border-radius;
                    margin-bottom: 16px;
                    border: 0;
                    overflow: hidden;

                    .ant-collapse-arrow {
                        line-height: 1.5;
                    }

                    svg {
                        background: grey;
                        border-radius: 50px;
                        color: white;
                        width: 20px;
                        height: 20px;
                        padding: 4px;
                    }

                    &:hover {
                        -webkit-box-shadow: 0px 0px 6px 2px rgba(204, 204, 204, 0.69);
                        -moz-box-shadow: 0px 0px 6px 2px rgba(204, 204, 204, 0.69);
                        box-shadow: 0px 0px 6px 2px rgba(204, 204, 204, 0.69);
                    }

                    .ant-collapse-header {
                        font-weight: bold;
                    }

                    .summary-collapse-panel-media-slider {
                        overflow: auto;
                        scroll-behavior: smooth;
                        align-items: center;
                        display: flex;
                        width: 100%;

                        /* width */
                        &::-webkit-scrollbar {
                            width: 0px;
                        }

                        .summary-collapse-panel-img-container {
                            margin-right: 16px;

                            img {
                                width: 220px;
                                height: 145px;
                                border-radius: $theme-border-radius;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

            .summary {
                width: 100%;
                max-height: 360px;
                overflow: auto;
                font-family: Rubik;
                font-size: 16px;
                line-height: 1.5;
                color: #000;
                margin-bottom: 15px;

                &::-webkit-scrollbar {
                    width: 0px;
                }
            }

            .title {
                margin-bottom: 7px;
                font-family: Rubik;
                font-size: 14px;
                font-weight: bold;
                line-height: 1.86;
                letter-spacing: -0.3px;
                color: #1e1e1e;
            }
        }

        .audioBook {
            display: flex;
            padding-left: 15px;

            .text {
                font-family: Rubik;
                font-size: 14px;
                line-height: 1.71;
                color: #000;
            }
        }

        .activity-cards-section {
            // width: 100%;
            padding: 30px;

            .activity-cards-container {
                flex-flow: row wrap;
                margin-top: 30px;

                .card {
                    width: 100px;
                    margin: 0px 0px 30px 0px;

                    .card-img {
                        img {
                            border-radius: $theme-border-radius;
                            width: 100px;
                            height: 100px;
                        }
                    }

                    .card-title {
                        margin-top: 8px;
                        font-size: 14px;
                        font-weight: bold;
                        color: #000000;
                    }

                    .activity-status {
                        font-size: 12px;
                        font-weight: 500;
                        color: #feaa07;
                        text-transform: capitalize;
                    }
                }
            }
        }
    }

    .ant-collapse-item-active {
        svg {
            background: orange !important;
        }
    }

    .division {
        width: 4%;
        background-color: transparent;
        height: 100%;
        flex: 0.01;
    }

    @media (max-width: 1023px) {
        flex-direction: column;

        .left {
            width: 100%;
            max-width: 100%;

            .left-heading {
                padding-top: 25px;
            }

            .mobile-back {
                color: #fff;
                top: 16px;
                position: absolute;
                left: 16px;
                font-size: 16px;
                font-weight: 500;
                display: block;
                cursor: pointer;
            }

            .mobile-bookmark {
                position: absolute;
                right: 0;
                top: 0;
                display: block;
            }
        }

        .description-card {
            width: 100%;
            max-width: 100%;

            .summary-card {
                max-width: 80vw;
                margin: auto;
            }

            // .close-icon {
            //   display: none;
            // }
        }
    }

    @media (max-width: 700px) and (orientation : landscape) {
        .left-heading {
            display: none;
        }
    }
}

.ant-modal-header {
    display: none;
}

.ant-modal-content {
    background-color: transparent;
}

.media-container {
    width: 100vw;
    height: 100vh;
    background: #fff;

    .ant-modal-content {
        height: 100vh;
    }
}

.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
    color: white !important;
}

.anticon {
    line-height: 1px !important;
}



.form_container{
width: 100%;
max-width: 75%;
margin: auto;
display: flex;
flex-direction: column;
align-items: center;
gap: 0.6rem;
height: 100%;

@media (max-width:768px) {
    max-width:80% ;
}

@media (max-width:480px) {
    max-width: 90%;
}
}

.form_container.page_second{
    max-width: 100%;
}

.form_item .ant-form-item-control-wrapper{
    width:100% !important;
}
.form_item{
    width: 100%;
}

.form_item .form_item_input{
    border-radius: $theme-border-radius !important;
    width: 100% !important;
    min-height: 50px !important;
    color: #0008 !important;
    border-width: 2px !important;
    background-color: #fff !important;
}

.custom-datepicker .ant-calendar-picker-input{
    border-radius: $theme-border-radius !important;
    width: 100% !important;
    min-height: 50px !important;
    border-width: 2px !important;
}

.form_submit_button{
    width: 100%;
    font-size: 1.3rem !important;
    font-weight: 500 !important;
    border: none !important;
    outline: none !important;
    border-radius: $theme-border-radius !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 1rem 0 !important;
    margin-left: 0 !important;
    color: #fff !important;
}

.form_submit_button.ant-btn{
    margin-left: 0 !important;
}

.assignee_type_container{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    place-items: center;
    gap: 2rem;
    width: 100%;
    justify-items: center;
    grid-template-rows: auto auto;
    // max-width: 80%;
    // margin: auto;

    @media (max-width:520px) {
        grid-template-columns: repeat(2,1fr);
    }

}

.assignee_type_card_container{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    border: 1px solid $magenta-color;
    padding: 1rem;
    border-radius: $theme-border-radius;
    box-shadow: 1px 1px 1px #e5c4ff;
    min-height:16rem;
    cursor: pointer;
    img{
        width: 100px;
        height: 100px;
    }
    p{
        margin-bottom: 0;
        font-size: 1.2rem;
        font-weight: 500;
        text-align: center;
    }

    .assignee_check_icon, .assignee_check_icon_circle{
        width: 30px;
        height: 30px;
        color: rgb(36, 210, 38);
    }
    .assignee_check_icon{
        width: 30px;
        height: 30px;
        color: $magenta-color;
    }

    @media (max-width:520px) {

        img{
            width: 60px;
            height: 60px;
        }

        p{
            font-size: 1rem;
            font-weight: 450;
        }

    }
}

.assignee_type_card_container:nth-child(3){

    @media (max-width:520px) {

        grid-column: 1/3;
        justify-self: center;
        width: 46%;
    }
    }

.assignee_type_card_container.active{
    border: 3px solid $magenta-color;
    box-shadow: 4px 4px 4px #cd9bf3;
}

.reader_type_select{
    width: 100%;
.ant-select-enabled{
    width: 100% !important;
}
.ant-select-selection{
    height: 100%;
    min-height:50px;
    border-radius: $theme-border-radius !important;
}

.ant-select-selection__rendered{
    display: flex;
    height: 100%;
    align-items: center;
    flex-wrap: wrap;
    min-height: 50px;
}

.ant-select-dropdown{
    // width: 39% !important;
    border-radius: $theme-border-radius !important;
}

}

.ant-select-dropdown.reader_type_drop-down{
    // width: 39% !important;
    border-radius: $theme-border-radius !important;
    // max-width: 360px !important;
}

.select_assignee_container{
    width: 100%;
}

.select_assignee_container .select_assignee_item{
    width: 100%;
    min-height:50px !important;
    height: 100%;
}

.select_assignee_item .ant-form-item{
    width: 100% !important;
    min-height:50px !important;
    height: 100%;
}

.select_assignee_item .ant-select-enabled{
    width: 100% !important;
}

.select_assignee_item .ant-select-selection{
    height: 100%;
    min-height:50px;
    border-radius: $theme-border-radius !important;
}

.select_assignee_item .ant-select-selection__rendered{
    display: flex;
    height: 100%;
    align-items: center;
    flex-wrap: wrap;
    min-height: 50px;
}
.select_assignee_item .ant-select-selection__clear{
    top: 45% !important;
}

.ant-select-dropdown.selectassigneedropdown{
    width: 28.5% !important;
    border-radius: $theme-border-radius !important;
    // max-width: 360px !important;
    min-width: 20% !important;
}

.select_assignee_item .ant-select-selection--multiple>ul>li{
    margin-top: 0 !important;
}

.form_btns_container{
    display: flex;
    width: 100%;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
}

.no_content_para{
    padding: 3rem 0;
    width: 100%;
    text-align: center;
}