@import 'assets/stylesheet/variables.scss';

.header {
  width: 100%;
  height: 50px;
  background: white;
  display: flex;
  padding: 5px 20px;
  // justify-content: space-between;
  gap: 1rem;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.05);
  position: sticky;
  top: 0px;
  z-index: 5;
  // img {
  //   height: 30px;
  // }
  button {
    background-color: $magenta-color;
    font-size: 10px;
    border: none;
    border-radius: 10px;
    //FREAD-1333
    margin-top: unset !important;
    display: none;
    //FREAD-1333
  }
  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    background-color: $magenta-color;
  }
}