@import 'assets/stylesheet/variables.scss';

// .ant-form-item-children textarea{
//     width: 100%;
//     line-height: 35px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     height: 44px;
//     min-height: 44px;
//     max-height: 112px;
//     overflow-y: hidden;
// }

.ant-calendar-picker{
    width:100%;
}
.description-container form .ant-form-item .ant-col .ant-form-item-control span textarea{
    padding:2% 12px !important;
}
.Srch_main{
    // position: absolute !important;
    // left: 0% !important;
    // width: 400px !important;

}
.component-profile .description-card .description-container .assignee .assignee-item {
    width:100% !important;
}
.component-profile {
    // display: flex;
    height: 100%;
    overflow: auto;
    border-radius: $theme-border-radius;

    .flex {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .bookmark-icon {
        box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.11);
    }

    .left {
        flex: 0.4;
        position: relative;

        .pack-points {
            margin-bottom: 15px;
            font-size: 12px;

            .read-time {
                border-radius: 14px;
                background-color: #ffffff;
                color: $theme-color;
                font-size: 12px;
                padding: 2px 6px;
                display: flex;
                height: 20px;
                width: fit-content;
            }
        }

        .left-top {
            position: absolute;
            top: 0px;
            padding-left: 23px;
            padding-top: 24px;
            color: #fff;
            // For shadow
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0.76) 0%, rgba(0, 0, 0, 0) 100%);
            width: 100%;
            padding: 19px;

            .left-heading {
                margin-top: 25px;
                color: #fff;
                font-size: 26px;
                font-weight: 500;
            }
        }

        .story-card {
            position: absolute;
            bottom: 0px;
            padding-left: 23px;
            padding-bottom: 24px;
            color: #fff;
            // For shadow
            background: linear-gradient(to top, rgba(0, 0, 0, 0.76) 0%, rgba(0, 0, 0, 0) 100%);
            width: 100%;
            padding: 19px;
            padding-top: 40px;

            .name {
                font-size: 26px;
                font-weight: 500;
            }

            .author-name {
                font-size: 16px;
            }
        }

        .bg-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            background-image: linear-gradient(359deg, #552d83, #432870 64%, #361e5f);
            border-radius: $theme-border-radius;
        }

        .left-header {
            padding-left: 30px;
            padding-top: 15px;
            text-shadow: 0 4px 10px rgba(0, 0, 0, 0.7);
            font-family: Rubik;
            font-size: 26px;
            font-weight: 500;
            line-height: 1.23;
            color: #fff;
            position: absolute;
        }

        .mobile-back {
            display: none;
        }

        .mobile-bookmark {
            display: none;
        }
    }

    .description-card {
        // flex: 0.6;
        // max-width: 60%;
        border-radius: $theme-border-radius;
        background-color: #f1f1f5;
        padding: 16px;

        .close-icon {
            position: absolute;
            top: 21px;
            right: 22px;
        }

        .success-container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-top: 50px;
        }

        .success-container-2 {
            .success-inner {
                display: flex;
            }

            button {
                background-color: $magenta-color;
                margin-top: 10px;
            }
        }

        .description-container {
            height: 80%;
            position: relative;
            // overflow: auto;
            align-items: center;
            margin: 10px 0;
            padding-right: 5px;

            .assignee {
                display: flex;
                justify-content: space-between;

                .assignee-item {
                    width: 30%;
                }
            }


        }

        .btn-container {
            position: absolute;
            right: 0;
            bottom: 0px;
            // button {
            //   width: 218px;
            //   height: 52px;
            //   padding: 0px;
            // }
        }

        .total-activities-text {
            font-weight: bold;
            color: $theme-text-color-dark;
            font-size: 20px;
        }

        .summary-card {
            max-width: 440px;

            .summary-collapse {
                background-color: #f1f1f5;
                padding: 5px;

                .summary-collapse-panel {
                    background: #ffffff;
                    border-radius: $theme-border-radius;
                    margin-bottom: 16px;
                    border: 0;
                    overflow: hidden;

                    .ant-collapse-arrow {
                        line-height: 1.5;
                    }

                    svg {
                        background: grey;
                        border-radius: 50px;
                        color: white;
                        width: 20px;
                        height: 20px;
                        padding: 4px;
                    }

                    &:hover {
                        -webkit-box-shadow: 0px 0px 6px 2px rgba(204, 204, 204, 0.69);
                        -moz-box-shadow: 0px 0px 6px 2px rgba(204, 204, 204, 0.69);
                        box-shadow: 0px 0px 6px 2px rgba(204, 204, 204, 0.69);
                    }

                    .ant-collapse-header {
                        font-weight: bold;
                    }

                    .summary-collapse-panel-media-slider {
                        overflow: auto;
                        scroll-behavior: smooth;
                        align-items: center;
                        display: flex;
                        width: 100%;

                        /* width */
                        &::-webkit-scrollbar {
                            width: 0px;
                        }

                        .summary-collapse-panel-img-container {
                            margin-right: 16px;

                            img {
                                width: 220px;
                                height: 145px;
                                border-radius: $theme-border-radius;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

            .summary {
                width: 100%;
                max-height: 360px;
                overflow: auto;
                font-family: Rubik;
                font-size: 16px;
                line-height: 1.5;
                color: #000;
                margin-bottom: 15px;

                &::-webkit-scrollbar {
                    width: 0px;
                }
            }

            .title {
                margin-bottom: 7px;
                font-family: Rubik;
                font-size: 14px;
                font-weight: bold;
                line-height: 1.86;
                letter-spacing: -0.3px;
                color: #1e1e1e;
            }
        }

        .audioBook {
            display: flex;
            padding-left: 15px;

            .text {
                font-family: Rubik;
                font-size: 14px;
                line-height: 1.71;
                color: #000;
            }
        }

        .activity-cards-section {
            // width: 100%;
            padding: 30px;

            .activity-cards-container {
                flex-flow: row wrap;
                margin-top: 30px;

                .card {
                    width: 100px;
                    margin: 0px 0px 30px 0px;

                    .card-img {
                        img {
                            border-radius: $theme-border-radius;
                            width: 100px;
                            height: 100px;
                        }
                    }

                    .card-title {
                        margin-top: 8px;
                        font-size: 14px;
                        font-weight: bold;
                        color: #000000;
                    }

                    .activity-status {
                        font-size: 12px;
                        font-weight: 500;
                        color: #feaa07;
                        text-transform: capitalize;
                    }
                }
            }
        }
    }

    .ant-collapse-item-active {
        svg {
            background: orange !important;
        }
    }

    .division {
        width: 4%;
        background-color: transparent;
        height: 100%;
        flex: 0.01;
    }

    @media (max-width: 1023px) {
        flex-direction: column;

        .left {
            width: 100%;
            max-width: 100%;

            .left-heading {
                padding-top: 25px;
            }

            .mobile-back {
                color: #fff;
                top: 16px;
                position: absolute;
                left: 16px;
                font-size: 16px;
                font-weight: 500;
                display: block;
                cursor: pointer;
            }

            .mobile-bookmark {
                position: absolute;
                right: 0;
                top: 0;
                display: block;
            }
        }

        .description-card {
            width: 100%;
            max-width: 100%;

            .summary-card {
                max-width: 80vw;
                margin: auto;
            }

            // .close-icon {
            //   display: none;
            // }
        }
    }

    @media (max-width: 700px) and (orientation : landscape) {
        .left-heading {
            display: none;
        }
    }
}

.ant-modal-header {
    display: none;
}

.ant-modal-content {
    background-color: transparent;
}

.media-container {
    width: 100vw;
    height: 100vh;
    background: #fff;

    .ant-modal-content {
        height: 100vh;
    }
}

.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
    color: white !important;
}

.anticon {
    line-height: 1px !important;
}

// .global-search div div div span div div {
//     width:112% !important;
// }