@import 'assets/stylesheet/variables.scss';

/* Define variables */
$mobile-breakpoint: 768px; /* Adjust as needed */

.timer-div{
  background: yellowgreen;
  width:100%;
  display: fixed;
  height:10%;
}

/* Styles for timer header */
.timer-header {
  width: fit-content;
  // height: 2rem;
  border-radius: 30px;
  background-color: $magenta-color ;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0.5rem;
  gap: 5px;
}

.timer-header h1 {

  z-index: 1;
  font-size: 22px;
  color: white;
  text-align: center;
  align-items: center;
  margin: 0;
}

.timer-icon {
  width: 1.5rem;
  height: 1.5rem;
}

/* Styles for close icon */
.icon-close {
     cursor: pointer;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 4px;
    // font-size: 38.92px;
    // line-height: 48.65px;
    background-color: $magenta-color;
    color: white;
    // border: 4px solid $magenta-color;
    // font-weight: bolder;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(2px 2px 2px rgb(145,52,216));
}

/* Media query for mobile views */
@media (max-width: $mobile-breakpoint) {
  /* Adjust styles for timer header */
  .timer-header h1 {
    font-size: 18px;

  }

  // .timer-header img {
  //   width: 100px; /* Make the image smaller */
  // }

  /* Adjust styles for close icon */
  // .icon-close {
  //   right: 15%;
  //   top: 36px;
  //   width: 20px;
  //   height: 20px;
  // }
}

@media (max-width:650px) {
  .icon-close{
    width: 30px;
    height: 30px;
  }
}

