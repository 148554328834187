@import 'assets/stylesheet/variables.scss';

.b2b_login_container{
    display: flex;
    height: 100%;
    width: 100%;
    min-height: calc(100vh - 50px);
    align-items: center;
    justify-content: center;
  background-color: #580e3a;

    .error_container{
        font-size: 38px;
        font-weight: 700;
        color: white;
        max-width: 75%;
        text-align: center;
        padding: 5rem;
        border-radius: $theme-border-radius;

        @media (max-width:780px) {
            font-size: 2rem;
        }
    }
}