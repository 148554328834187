@import "assets/stylesheet/variables.scss";

.search_modal_container{
    width: 100%;
    height: 100%;
    min-width: 40%;
    min-height: 40%;
    border-radius: $theme-border-radius;
    background-color: #ffff;
    padding: 2rem;
    position: relative;
}

.search_modal_header{
    width: 100%;
}

.search_modal_body{
    display: flex;
    flex-direction: column;
}

.search_modal_close_icon{
    background-color: $magenta-color;
    padding: 6px;
    border: 2px solid $magenta-color;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: white;
    font-weight: 700;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;

}

.recent_history_heading{
    font-size: 1.2rem;
    color: rgb(168, 167, 167);
    font-weight: 500;
    margin-top: 1rem;
}