@import "assets/stylesheet/variables.scss";

.imageIcon {
  width: 6% !important ;
  // padding-right: 8% !important;
  // padding-bottom: 4% !important;
  margin-right: 1%;
}

.worksheetlink {
  color: inherit;
  text-decoration: none;
}

.worksheetbox {
  height: 90px;
  border: 2px solid gray;
  padding: 20px;
  margin: 20px;
  font-family: Rubik;
  width: 142px;
}

.homebtn {
  margin-bottom: 3% !important;
}

.ant-btn {
  // margin-top: 20px !important;
  margin-left: 5% !important;
}

.updatedpara {
  margin-left: 12px !important;
}

.updatedpara2 {
  margin-left: 21px !important;
}

.ant-btn {
  background-color: $magenta-color;
  margin-top: 10px;
}

.btn-link {
  text-decoration: none !important;
}

$grey-color: #524e59;
$grey-color-light: #a8a7ac;

.class-details {
  font-family: "Rubik";
  height: 90%;
  overflow-y: hidden;

  .ant-modal {
    color: black;
    @media screen and (max-width: 460px) {
      width: 100% !important;
      max-width: unset;
    }
  }

  @media screen and (max-width: 460px) {
    overflow-y: hidden;
    height: 100%;
    /*::-webkit-scrollbar {
      display: none;
    }*/
  }

  .ant-modal-content {
    overflow-y: auto;
    height: 72vh;
    background: white;
    @media screen and (max-width: 460px) {
      overflow-y: auto;
      height: 85vh;
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
    }
  }

  .ant-modal-close {
    display: inline-block;

    .ant-modal-close-x {
      position: absolute;
      right: 9px;
      top: 5px;
    }
  }

  .anticon-close {
    background: #ececec !important;
    border-radius: 100px !important;
    padding: 9px !important;
    // display: inline-block;
    display: inline;
  }

  .ant-modal-body {
    padding: 20px 25px !important;
    background-color: white !important;
    @media screen and (max-width: 460px) {
      padding: 0px !important;
    }
  }

  .attended-btn {
    border: 2px solid #00af54;
    background-color: transparent;
    outline: none;
    color: #00af54;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 400;
    padding: 2px 8px;
    border-radius: 5px;
    font-weight: 500;
  }

  h1,
  h2 {
    color: $grey-color;
  }

  .modal-title {
    font-size: 28px;
    @media screen and (max-width: 460px) {
      padding: 20px 0 20px 20px;
      font-size: 24px;
    }
  }

  .modal-data {
    height: 55vh;
    overflow: auto;
    @media screen and (max-width: 460px) {
      padding: 0 20px !important;
    }
  }

  .mb-10 {
    margin-bottom: 10px;
    @media screen and (max-width: 460px) {
      margin-bottom: 0 !important;
    }
  }

  .detail-row {
    margin-bottom: 50px;
    border-top: 1px solid #ececec;
    padding-top: 30px;

    &:nth-of-type(1) {
      padding-top: 0px;
      border: 0px;
    }
  }

  .subject-title {
    @media screen and (max-width: 460px) {
      border-bottom: 1px solid #828a9d;
      padding: 0 !important;
    }

    .pill {
      margin-left: 15px;
    }
  }

  .shadow-box {
    margin-bottom: 30px;
  }

  .h-500 {
    min-height: 500px;
  }

  .tab-content {
    color: $grey-color;
    font-size: 16px;
  }

  .back-btn {
    color: $grey-color;
    font-weight: 500;
    border: none;
    padding: none;
    margin: none;
    margin-left: -12px;
    font-size: 16px;
    width: auto;
    height: auto;
    margin-left: 3px;
  }

  .d-flex {
    display: flex;
    flex-direction: row;
    align-items: center;

    &.sb {
      justify-content: space-between;
    }
  }

  .j-c-sb {
    justify-content: space-between;
  }

  .btn-outline-purple {
    border: 2px solid $magenta-color;
    color: $magenta-color;
    font-weight: bold;
    width: auto;
    height: auto;
    padding: 5px 5px;
    border-radius: 5px;
  }

  .homework-text {
    font-weight: bold;
    color: #219653;
    margin-left: 20px;
  }

  .class-timings {
    align-items: flex-start;

    svg {
      margin-right: 10px;
      margin-bottom: 4px;
    }

    h3 {
      margin-right: 20px;
      color: $grey-color !important;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 0px;
    }

    .timeSection {
      flex-wrap: wrap;

      h3 {
        margin-bottom: 12px;
      }
    }
  }

  .seperator-20 {
    width: 100%;
    height: 20px;
  }

  .description {
    //color: $grey-color;

    .icon-text {
      margin-bottom: 10px;
    }

    h5 {
      font-size: 14px;
      margin-bottom: 0px;
      font-weight: 500;
    }

    p {
      //font-weight: 500;
      margin-bottom: 30px;
      margin-left: 0.5rem;
      //color: #97959B;
    }
  }

  .ant-modal-footer {
    display: none;
  }

  .ant-modal-header {
    display: inline;
  }

  .link-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;

    .link-card {
      width: 240px;
      min-height: 200px;
      border-radius: 15px;
      overflow: hidden;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.11);
      margin-right: 20px;
      margin-top: 5px;
      margin-bottom: 15px;
      padding-bottom: 10px;
      cursor: pointer;

      h4 {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 3px;
      }

      .card-image {
        width: 100%;
        height: 140px;
        object-fit: cover;
      }

      .card-footer {
        padding: 10px 15px;
        background: none;

        .author {
          font-weight: normal;
          color: #524e59;
          font-size: 12px;
          margin: 0px;

          .name {
            color: $magenta-color;
          }
        }
      }
    }
  }
}
