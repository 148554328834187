@import 'assets/stylesheet/variables.scss';

.epub-content {
  overflow: hidden !important;
}

.image img{
  height:200px !important;
}

// .epubfile{
//   width:100% !important;
//   height: 100% !important;
// }

.read_along_container{
  width: 100vw;
  display: flex;
  justify-content: space-between;
  gap:8px;
  align-items: center;
  height: 100vh;
}

#epub-file .epub-view{
  width: 100% !important;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.modal-content {
  // position: absolute;
  background-color: white;
  border-radius: $theme-border-radius;
  padding: 20px 70px;
  max-height: 75%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // left: 15%;
  // width: 70%;
  // right: 15%;
  // top: 10%;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.navigation-icons {
  // display: flex;
  // justify-content: space-between;
  width: 100%;
  margin-top: auto;
  padding: 20px 0;
  display: absolute;
  top: 50%;
}

.left-icon,
.right-icon {
  cursor: pointer;
  font-size: 30px;
  color: $magenta-color;
  align-items: center;
}

.rectangle-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.rectangle {
  border: 2px solid #f1eef5;
  justify-content: center;
  text-align: center;
  border-radius: $theme-border-radius;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.modal-img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.modal-text-container {
  padding: 36px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-text {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.modal-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.record-button,
.end-button,
.try-again-button {
  margin: 0 10px;
  width: 271px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: $magenta-color;
  border: $magenta-color solid 2px;
  border-radius: $theme-border-radius;
  color: white;
  position: absolute;
  bottom: 10px;
}

.left-nav {
  position: absolute;
  left: 7%;
  top: 50%;
  font-size: 70px;
}

.right-nav {
  position: absolute;
  right: 7%;
  top: 50%;
  font-size: 70px;
}

@media screen and (min-width: 576px) and (max-width: 600px) {
  .modal-content {
    padding: 40px; /* Adjust padding for smaller screens */
    // left: 10%;
    // right: 10%;
    // width: 80%;
    max-height: 75%;
    border-color: transparent; /* Adjust max-height for smaller screens */
  }

  .modal-text {
    font-size: 18px; /* Adjust font size for smaller screens */
    line-height: 26px;
    padding-left: 10px; /* Adjust line height for smaller screens */
  }

  .start-button,
  .try-again-button {
    width: 100%; /* Make buttons full width on smaller screens */
  }
  .image-container {
    padding: 10px;
  }
  .epubfile {
    margin-top: 65%;
  }
  .left-nav {
    position: absolute;
    left: 1%;
    top: 50%;
    font-size: 30px;
  }

  .right-nav {
    position: absolute;
    right: 1%;
    top: 50%;
    font-size: 30px;
  }
  .record-button {
    bottom: 3%;
  }
  #api-response-text {
    font-size: 12px;
  }
  .modal-content {
    margin-top: -12% !important;
  }
  .text-center {
    position: absolute;
    bottom: 9%;
    left:45%;
    display: flex;
    justify-content: center;
    margin-top: 4px;
  }
  .alert-class {
    position: absolute;
    bottom: 9%;
    margin-top: 4px;
    // display: flex;
    text-align: center;
  }
  .modal-content {
    max-height: 90% !important;
    overflow: scroll;
  }
  #api-response-text {
    position: absolute;
    bottom: 8%;
    // left: 50%;
  }
}

.text-center {
  position: absolute;
  bottom: 9%;
  left:45%;
}

.error-message {
  position: absolute;
  bottom: 8%;
}

.loading-symbol {
  position: absolute;
  bottom: 8%;
}

#api-response-text {
  // position: absolute;
  // bottom: 8%;
  // left:26%;
  display: flex;
  position: absolute;
  bottom: 8%;
  // left: 40%;
  // width: 42%;
  // right: 29%;
}

@media screen and (max-width: 576px) {
  .record-button {
    position: absolute;
    bottom: 1.5% !important;
    padding: 8px 15px;
  }

  #api-response-text {
    display: flex;
    position: absolute;
    bottom: 9%;
    // left: 15%;
    // width: 70%;
    // right: 15%;
    font-size: 10px !important;
    // margin: auto;
  }

  .modal-content {
    padding: 40px; /* Adjust padding for smaller screens */
    // left: 10%;
    // right: 10%;
    // width: 75%;
    max-height: 60% !important;
    border-color: transparent; /* Adjust max-height for smaller screens */
  }

  .modal-text {
    font-size: 18px; /* Adjust font size for smaller screens */
    line-height: 26px;
    padding-left: 10px; /* Adjust line height for smaller screens */
  }

  .start-button,
  .try-again-button {
    width: 100%; /* Make buttons full width on smaller screens */
  }
  .image-container {
    padding: 10px;
  }
  .epubfile {
    margin-top: 65%;
  }
  .left-nav {
    position: absolute;
    left: 1%;
    top: 50%;
    font-size: 30px;
  }

  .right-nav {
    position: absolute;
    right: 1%;
    top: 50%;
    font-size: 30px;
  }
  .modal-content {
    margin-top: -12% !important;
  }

  .modal-content {

    overflow: scroll;
  }
  .text-center{
    bottom:10% !important;
    left:35% !important;
  }

  #epub-file .epub-container{
    min-width: 0px !important;
  }

}

.text-center{
  position: absolute;
  bottom:6%;
  left:45%;
}


.read_along_read_aloud_btns_container{
  display: flex;
  width: fit-content;
  column-gap: 2.5rem;
  row-gap: 1rem;
  position: absolute;
  bottom:10px;
  z-index: 1;
  max-width:100%;
  flex-wrap: wrap;
 button {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 0px !important;
    font-size: 38.92px !important;
    line-height: 48.65px;
    background-color: $magenta-color!important;
    color: white !important;
    border: 4px solid $magenta-color !important;
    font-weight: bolder !important;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(2px 2px 2px $magenta-color);
    z-index: 100;

    &:disabled {
      color: #fff !important;
      opacity: 0.5;
    }

    svg{
      width:25px;
      height: 27px;
    }
  }
}