@import 'assets/stylesheet/variables.scss';

.assignments-list {
    // margin: 10% 0 0 10%;
    display: flex;
    flex-wrap: wrap;
}

.assignment_new {
    width: 90%;
    padding: 1%;
    margin: 1%;
    border-radius: $theme-border-radius;
    background: $theme-light-color;
    border: 6px solid $magenta-color;
    box-shadow: 0 4px 4px 0 #00000040;
    margin-left:5% !important;
    cursor: pointer;
}

.assigned-date, .due-date, .progress {
    font-size: 12px;
    font-weight: 600;

    color: white;
    text-align: center;
    border-radius: $theme-border-radius;
    background-color: $magenta-color;
}
.assigned-date, .due-date{
    // background: #842C86 ;
    padding: 5px;
}
.progress{
    margin-top: 25px;
    background: #FC9D45;
}

.bar {
    border: 3px solid $magenta-color ;
}

.name, .description {
    font-size: 21px;
    font-weight: 700;
    text-align: left;
    color: black ;
    word-wrap: break-word;
}

.description {
    margin-top: 3%;
    font-size: 10px;
}

.type {
    border-radius: $theme-border-radius;
}

.story, .activity, .ntype, .quiz-type, .not-started, .completed, .on-going, .gp-unit {
    width: 5rem;
    height: 1.5rem;
    text-align: center;
    box-shadow: 0 4px 4px 0 #00000040;
}

.story .text, .activity .text, .ntype .text, .quiz-type .text, .not-started .text, .completed .text, .on-going .text, .gp-unit .text {
    font-size: 13px;
    font-weight: 700;
    line-height: 6px;
    letter-spacing: 0;
    text-align: center;
    color: white;
    text-transform: uppercase;
}

.story { background: $magenta-color; }
.activity { background: #9B6C26; color: white; }
.ntype { background: #2F83C2;color: white;  }
.quiz-type { background: #DB518B; }
.gp-unit{background: #b774e7;}
.not-started { background: #D72D2D; width: 8rem; }
.completed { background: #7DCF3C; width: 8rem; }
.on-going { background: #FC9D45; width: 8rem; }

.assignment-image {
    width: 50px;
    height: 78.57px;
    /* Not sure about these top and left values */
}

.assignment-child{
    position: relative;
}
.assignment-child img{
    position:absolute;
    bottom:0;
    right:1;

}
// .assignment-switch{
//     position: absolute;
//     top: -171px;
//     right: -353px;
// }
.no-assignments-found {
    margin-top: 10%;
    color: red;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
}
.no-assignments{
    margin: auto;
    margin-top: 10%;
    font-size: 1.5rem;
    border: unset !important;
    height: 60vh;
    align-items: center;
    justify-content: center;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    // text-transform: capitalize;
    border: 2px solid #3f4154;
    color: #3f4154;
    -webkit-filter: drop-shadow(1px 1px 1px rgba(44, 11, 74, 0.15));
    filter: drop-shadow(1px 1px 1px rgba(44, 11, 74, 0.15));
    border-radius: $theme-border-radius;
    // margin-left: 15%;
    width:70%;
}

.assignment_status_container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
    margin: 3rem 0 2rem 0 ;

}
.assignment_status_container2{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    margin: 3rem 0 2rem 0 ;

}

@media (max-width:1040px) {
    .assignment_status_container{
        grid-template-columns: repeat(2, 1fr);
    }
}


@media (max-width:480px) {
    .assignment-dates{
        padding: 0 5px !important;
    }
    .assignment_status_container{
        grid-template-columns: repeat(1, 1fr);
    }
    .assignment_status_container2{
        grid-template-columns: repeat(1, 1fr);
    }
}
.assignments_loading_container{
    display: flex;
    flex-direction: column;
    gap: 6px;
    justify-content: center;
    align-items: center;
    color: $magenta-color;
    margin: 1rem 0;
}
