@import 'assets/stylesheet/variables.scss';

.ant-select-dropdown--single{
  // width:400px ;

}
.ant-select-enabled{
  width:500px ;
}
.ant-form-item{
  margin-bottom: 10px !important;
}
.anticon-down{
color:black !important;
}
select{
  width:500px;
}
option{
  width:400px;
}
.ant-select-dropdown--multiple{
  min-width:  400px !important;
}
.signup-container {
  height: 100vh;
  // width: 100%;
  // background-color: #1b0838;
  background-color: #580e3a;

  // background-image: linear-gradient(360deg, #1B0838, #1B0838, #1B0838);
  display: flex;
  align-items: center;
  overflow-y: auto;
  //FREAD-1234
  .ant-btn{
    margin-top: unset !important;
    margin-left: unset !important;
  }
  //FREAD-1234
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  .column {
    float: left;
    width: 50%;
    // padding: 10px;
  }
  .left-part{
    text-align: center;
    flex: 0.55;
    .user-illustration {
      margin-bottom: -90px;
      user-select: none;
      -webkit-user-drag: none;
      width: 100%;
    }
    .title {
      font-size: 37px;
      font-weight: 500;
      color: #ffffff;
      margin-top: 24px;
    }
    .gradient-text {
      background-image: radial-gradient(circle at 50% 3%, #e6b815, #ff8a23);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      object-fit: contain;
      font-size: 68px;
      font-weight: 500;
    }
    .helper-text {
      font-size: 16px;
      color: #ffffff;
      opacity: 0.8;
    }
    .image-banner{
      width: 85%;
    }
  }
  .right-part {
    .mobile-logo {
      display: none;
    }
    flex: 0.45;
    padding: 0 96px;
    .login-card {
      border-radius: $theme-border-radius;
      background-color: transparent;
      padding: 80px 56px;

      .ant-col{
        width: 100% !important;

        .ant-select-enabled{
          width: 100% !important;
        }
      }

      .heading {
        font-size: 32px;
        font-weight: 800;
        color: #ffffff;
      }
      .sub-heading {
        font-size: 20px;
        font-weight: 500;
        color: #ffffff;
        line-height: 39px;
        margin-bottom: 48px;
      }
      .otp-label{
        font-size: 20px;
        font-weight: 500;
        color: #FFF;
        line-height: 39px;
        margin-top: 30px;
        margin-bottom: 10px;
      }
      .ant-select-selection{
        background: #D7DDE8;
        // height: 40px;
        border-radius: $theme-border-radius;
        width: 300%;

      }
      .ant-select-selection--single{
        height: 40px;
      }
      .ant-select-selection__rendered{
        line-height: 40px;
      }
      .ant-select-selection__placeholder{
        color: black;
      }
      .ant-select-selection-selected-value {
        padding-top: 0 !important;
      }
      .input-class{
        height: 40px;
        background: transparent;
        border-radius: $theme-border-radius;
        color: white;
        &::placeholder {
          color: #555555;
          font-size: 14px;
        }
         &:focus{
          box-shadow: none !important;
        }
      }
      .width-100{
        width: inherit;
      }
      .flag-class{
        background-color: #1b0838;
        border-radius: $theme-border-radius 0 0 $theme-border-radius;
        .selected-flag{
          &:hover{
            background-color:transparent;
          }
        }
      }
      button {
        background-color: $magenta-color;
        color: #fff;
        height: 52px;
        font-size: 16px;
        border: none;
        font-weight: 400;
        border-radius: $theme-border-radius;
        // margin-right: 16px;
      }
      button:disabled {
        background-color: #903fce;
        opacity: .5;
      }
      .signup-text{
        color: #878787;
        font-size: 12px;
        font-weight: 600;
      }
      .timer-text{
        color: white;
        font-size: 12px;
        font-weight: 600;
        padding: 5px !important;
        background-color: transparent !important;
      }
      .seprator{
        margin:0 10px 0 10px;
        border-right: 1px solid #fff
      }
      .signup-link{
        color: #fff;
        font-size: 12px;
        font-weight: 600;
        text-decoration: underline;
      }
      .resend-link{
        color: #fff;
        font-size: 12px;
        font-weight: 600;
        padding: 5px !important;
        background-color: transparent !important;
        span{
          text-decoration: underline !important;
        }
      }
      a{
        color: $theme-color;
        font-weight: 600;
        cursor: pointer;
      }
      .label {
        font-size: 16px;
        margin-bottom: 8px;
        font-weight: 400;
      }
      .ant-select-dropdown {
        border-radius: $theme-border-radius !important;
        // overflow: hidden !important;
        width: 45% !important;
      }
      .school-code-yes-no {
        width: 24%;
        font-weight: 500;
        margin-left: 0px !important;
      }
      // .ant-btn {
      //   padding-left: 0px !important;
      // }


    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1272px) {

    .right-part {
      padding: 0 24px;
      // flex: 0.55;
      .login-card {
        padding-left: 20px;
        padding-right: 35px;
      }
    }

  }
  @media screen and (max-width: 1024px) {
    background-image: #1B0838;
    //FREAD-1234
    .seprator{
      margin:0px 7px 0px 7px;
    }
    .ant-btn.resend-link{
      padding:3px !important;
    }
    .btn_sep_1234{
      display:flex !important;
    }
    //FREAD-1234
    .left-part {
      display: none;
    }
    .input-focused {
      margin-top: 100px;
    }
    .right-part {
      width: 100%;
      padding: 0;
      flex: 1;
      .mobile-logo {
        display: block;
        position: absolute;
        top: 40px;
        left: 50%;
        transform: translateX(-50%);
      }
      .login-card {
        padding: 0px 30px;
        background: transparent;
        box-shadow: none;
        color: #fff;
        .ant-form {
          color: #fff;
        }
        .sub-heading {
          color: #fff;
          opacity: 1;
        }
        a {
          color: #fff;
        }
        input {
          color: rgba(0, 0, 0, 0.65);
        }
      }
    }
  }
}
.react-tel-input .flag-dropdown.open .selected-flag{
  background: transparent !important;
}


// .signup-container .right-part .login-card .ant-btn {
//   padding-left: 0px !important;
// }


.signup-container .right-part .login-card  {

  .ant-select-selection-selected-value {
    color: black;
  }
}

// .signup-container .right-part .login-card .ant-select-selection {
//   height: 60px !important;
// }

.signup-container .right-part .login-card .ant-select-selection-selected-value {
  color: #808080;
}