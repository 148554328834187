@import 'assets/stylesheet/variables.scss';

.read-along-layout {
  width: 100%;
  overflow: hidden;
}

  .banner {
    width: 93%;
    height: 100%;
    object-fit: cover;
    margin-left: 3.5%;
    margin-top:7%;

    .banner-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content-container {
    margin-top: 4%;
    padding-left: 4.5%;
    padding-right: 4.5%;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 48px 68px;
  }

  .grid-item {
    margin-bottom: 20px;

    .grid-img {
      border-radius: $theme-border-radius;
      width: 100%;
      height: auto;
      border-radius: $theme-border-radius $theme-border-radius 0 0;
    }

    .grid-text {
      font-family: Inter;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      padding: 16px;
      border-radius: 0 0 $theme-border-radius $theme-border-radius;
      background-color: #fff;
    }
  }

  /* Media query for smaller screens */
  @media (max-width: 768px) {
    .banner {
      margin-top: 22%;
      margin-bottom: 2% !important;
    }

    .grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;
    }

    .read-along-layout {
      padding-left: 5px;
      padding-right: 5px;
      padding-top: 70px;
    }
  }


/* Modal Page Styles */
.modal-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.modal-container {
  background: #f1eef5;
  width: 100%;
  max-width: 1042px;
  height: 694px;
  border-radius: $theme-border-radius;
  position: relative;
  padding: 36px;
  border: 2px solid #f1eef5;
  box-sizing: border-box;

  @media (max-width: 768px) {
    max-width: 970px;
    height: auto;
    top: 0;
    left: 0;
    right: 0;
    margin: 36px 0;
    border-radius: $theme-border-radius;
  }
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .modal-img {
    width: 100%;
    max-width: 600px;
    max-height: 300px;
    height: auto;
    border-radius: $theme-border-radius;
    box-shadow: 0px 4px 10px #2c0b4a26;
  }

  .modal-text {
    margin-top: 36px;
    text-align: center;
  }

  .modal-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 36px;
  }

  .modal-button {
    border-radius: $theme-border-radius;
    background: linear-gradient(0deg, #1e7be2, #1e7be2);
    border: 2px solid $magenta-color;
    color: #fff;
    padding: 12px 24px;
    cursor: pointer;
    transition: background 0.3s ease;
    &:hover {
      background: linear-gradient(0deg, $magenta-color, $magenta-color);
    }
  }
}

@media (max-width: 768px) {
  .modal-content {
    padding: 20px;
  }

  .modal-img {
    max-height: 100%;
  }
}

@media (min-width: 768px) {
  .read-along-layout {
    padding: 50px;
  }
}
